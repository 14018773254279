import './index.css';
import { Link } from 'react-router-dom';

const contactmail = 'contact@gmail.com'

function Footer() {
    return (
        <div className="footer" id='footerbar'>
        </div>
    );
};

export default Footer;